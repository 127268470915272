import { createContext, PropsWithChildren, useContext, useState } from 'react';
import {
  ICustomerRecord,
  IHelpRecord,
  IRefundRecord, IRequestLinkRecord,
  IRequestRecord,
  ITagRecord,
  IVoiceMessageRecord
} from '@interfaces';

type Props = {
  type: 'help' | 'refund' | 'link';
  voiceMessage?: IVoiceMessageRecord;
  help?: IHelpRecord;
  refund?: IRefundRecord;
  request?: IRequestRecord;
  requestLink?: IRequestLinkRecord;
};

type RequestStateContextProps = {
  type: 'help' | 'refund' | 'link';
  setType: (type: 'help' | 'refund' | 'link') => void;
  help?: IHelpRecord;
  setHelp: (help: IHelpRecord) => void;
  refund?: IRefundRecord;
  setRefund: (refund: IRefundRecord) => void;
  customer?: ICustomerRecord;
  setCustomer: (customer: ICustomerRecord) => void;
  tag?: ITagRecord;
  setTag: (tag: ITagRecord) => void;
  request?: IRequestRecord;
  setRequest: (request: IRequestRecord) => void;
  voiceMessage?: IVoiceMessageRecord;
  requestLink?: IRequestLinkRecord;
  isTagLoading: boolean;
  setIsTagLoading: (loading: boolean) => void;
  newCustomer: () => void;
  reset: () => void;
};

export const RequestStateContext = createContext<RequestStateContextProps>(null);

export const RequestStateProvider = ({
  type: _type, voiceMessage, help: _help, refund: _refund, request: _request, requestLink, children
}: PropsWithChildren<Props>) => {
  const [type, setType] = useState(_type);
  const [customer, setCustomer] = useState<ICustomerRecord>(voiceMessage?.customer ?? _help?.customer ?? _refund?.customer);
  const [tag, setTag] = useState<ITagRecord>(voiceMessage?.tag ?? _help?.tag ?? _refund?.tag);
  const [help, setHelp] = useState<IHelpRecord>(_help);
  const [refund, setRefund] = useState<IRefundRecord>(_refund);
  const [request, setRequest] = useState<IRequestRecord>(_refund?.request ?? _help?.request ?? voiceMessage?.request ?? _request);
  const [isTagLoading, setIsTagLoading] = useState(false);

  const newCustomer = () => {
    setCustomer({
      name: voiceMessage?.name ?? '',
      email: voiceMessage?.email ?? '',
      phone_number: voiceMessage?.from_phone_number ?? '',
      settings: {
        feedback_exclude_automation: false,
        refunds_exclude_automation: false,
        notifications_unsubscribed: false,
      },
    } as ICustomerRecord);
  };

  const reset = () => {
    setCustomer(null);
    setTag(null);
  };

  return <RequestStateContext.Provider value={{
    type, setType,
    customer, setCustomer,
    help, setHelp,
    refund, setRefund,
    request, setRequest,
    tag, setTag, isTagLoading, setIsTagLoading,
    voiceMessage, requestLink, newCustomer, reset
  }}>
    { children }
  </RequestStateContext.Provider>
};

export function useRequestState() {
  const context = useContext(RequestStateContext);

  if (!context) {
    throw new Error(
      'useRequestState hook was called outside of RequestStateProvider context'
    );
  }

  return context;
}
