import React, { ReactNode, useState } from 'react';
import Link from 'next/link';
import { CanAccess, ITreeMenu, useMenu } from '@refinedev/core';
import { AppShell, ScrollArea, AppShellNavbarProps, NavLink, useMantineTheme } from '@mantine/core';
import { IconExternalLink, IconHelp } from '@tabler/icons-react';
import { useIdentity } from '@components/data/Identity.context';
import { useFeature } from 'flagged';
import { resolveBillingPortalUrl, resolveCustomerAppUrl } from '@libraries/helpers';
import { SidebarWalletSection } from '@components/layout/SidebarWalletSection';
import { useMediaQuery } from '@mantine/hooks';
import { SidebarOverlayMenu } from '@components/layout/SidebarOverlayMenu';

import classes from './Sidebar.module.css';
import { NavActionBar } from '@components/layout/NavActionBar';

interface SidebarProps extends Omit<AppShellNavbarProps, 'children'> {}

export function Sidebar(props: SidebarProps) {
  const { menuItems, selectedKey } = useMenu();
  const { identity } = useIdentity();
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);

  const isOwner = useFeature('owner');

  const link = (item: ITreeMenu, children?: ReactNode[]) => {
    const activeKey = selectedKey === '/' ? '/dashboard/' : selectedKey + '/';
    const isSelected = activeKey.includes(item.route === '/' ? '/dashboard/' : item.route + '/');

    if (children?.length) {
      return <CanAccess
        key={item.route}
        resource={item.name.toLowerCase()}
        action="list"
      >
        <NavLink key={item.route}
                 mt="sm"
                 label={item.label}
                 leftSection={item.icon}
                 children={children}
                 onClick={() => setOpened(false)}
                 defaultOpened={isSelected} />
      </CanAccess>;
    }

    return <CanAccess
      key={item.route}
      resource={item.name.toLowerCase()}
      action="list"
    >
      <NavLink renderRoot={(props) => <Link href={item.route} {...props} />}
               my={ !item.meta.parent ? 'sm' : 0 }
               key={item.route}
               label={item.label}
               leftSection={item.icon}
               onClick={() => setOpened(false)}
               active={isSelected} />
    </CanAccess>;
  }

  const menu = menuItems
    .filter(item => item.name !== 'settings')
    .map((item) => {
      const children = item.children.length ? item.children.map((o) => link(o)) : [];
      return link(item, children);
    });

  const dropdownMenu = menuItems
    .filter(item => item.name === 'settings')
    .map((item) => {
      return item.children.map((item) => link(item));
    });

  // Allows the menu to rerender correctly when switching organisations
  const MenuItems = () => <>
    { menu }
  </>;

  return (
    <AppShell.Navbar w={{ sm: 280 }} py={0} p="md" className={classes.navbar} { ...props }>
      <AppShell.Section className={classes.section}>
        <SidebarOverlayMenu menu={dropdownMenu} opened={opened} setMenu={setOpened} />
      </AppShell.Section>

      { identity?.owner && <>
        <NavActionBar />
      </>}

      <AppShell.Section grow component={ScrollArea}>
        <MenuItems />

        { isMobile && <SidebarWalletSection /> }
      </AppShell.Section>

      { !isMobile && <SidebarWalletSection /> }

      { isOwner && <>
        { identity?.owner?.stripe_id && <AppShell.Section className={classes.footer}>
          <NavLink
            href={resolveBillingPortalUrl()}
            target="_blank"
            label="Manage Subscription"
            leftSection={<IconExternalLink size={18} stroke={1.5} />}
            styles={{ label: { fontWeight: 400 } }}
            px={16}
          />
        </AppShell.Section>}
      </> }

      { identity?.owner && <>
        { identity.owner.company_url_slug && <AppShell.Section className={classes.footer}>
          <NavLink
            href={resolveCustomerAppUrl(identity.owner.company_url_slug)}
            target="_blank"
            label="View App"
            leftSection={<IconExternalLink size={18} stroke={1.5} />}
            styles={{ label: { fontWeight: 400 } }}
            px={16}
          />
        </AppShell.Section>}
      </> }

      <AppShell.Section className={classes.footer}>
        <NavLink
          href="http://docs.zippyassist.com/en/"
          target="_blank"
          label="Help Center"
          leftSection={<IconHelp size={18} stroke={1.5} />}
          styles={{ label: { fontWeight: 400 } }}
          px={16}
        />
      </AppShell.Section>

    </AppShell.Navbar>
  );
}
